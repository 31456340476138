import React, { useEffect, useRef, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { AiOutlineSearch } from "react-icons/ai";
import axios from "axios";
import { useTranslation } from "react-i18next";
import i18n from "./i18n";
import Swal from 'sweetalert2'; // Import SweetAlert
import { Footer } from "rsuite";
import parse from 'react-html-parser';

function BonusRequest() {
  const { id } = useParams();
  const [bonuss, setBonus] = useState([]);
  const { t } = useTranslation();
  const [bonus_req, setBonusReq] = useState({
    description: "",
  });
  const { description } = bonus_req;
  const Photo = useRef(null);
  const [user, setUser] = useState("");

  useEffect(() => {
    var user_id = localStorage.getItem("id");
    if (!user_id) {
      window.location.href = '/';
  }
    setUser(user_id);
    loadBonus();
  }, []);

  const loadBonus = async () => {
    try {
      const result = await axios.get(
        "https://changermoney.com/exchange/dashboard/bonus/readAll.php"
      );
      setBonus(result.data.reverse());
    } catch (error) {
      console.error("Error loading bonuses:", error);
    }
  };

  const onInputChange = (e) => {
    setBonusReq({ ...bonus_req, [e.target.name]: e.target.value });
  };

  const submit = async (e) => {
    e.preventDefault();
    try {
      let formData = new FormData();
      formData.append("user_id", user);
      formData.append("bonus_id", id);
      formData.append("description", bonus_req.description);
      formData.append('image', Photo.current.files[0]);

      const response = await axios({
        method: "post",
        url: "https://changermoney.com/exchange/dashboard/bonus_req/readAll.php",
        data: formData,
        config: { headers: { "Content-Type": "multipart/form-data" } },
      });

      if (response.data) {
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: 'Thanks, we will respond you',
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Failed',
          text: 'Submission failed',
        });
      }
    } catch (error) {
      console.error("Error during Review:", error);
      Swal.fire({
        icon: 'error',
        title: 'Failed',
        text: 'Submission failed',
      });
    }
  };

  return (
    <div>
      <Container className="SHOPALLL">
        <Row> 
          {bonuss.map((bonus) => (
            <>
              {bonus.id === id && (
                <Col lg="9" md="9" sm="9" className="bllogsdetail" key={bonus.id}>
                  <img
                    src={`https://changermoney.com/exchange/uploads/bonuss/${bonus.image}`}
                    alt=""
                  />
                  <h3>{t("dir") === "ltr" ? bonus.title : bonus.title_ar}</h3>
                  <p>
                    {t("dir") === "ltr" ? parse(bonus.description) : parse(bonus.description_ar)}
                  </p>
                  <div>
                    <form onSubmit={submit} id="contact-form" className=" py-5 my-5">
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="section-heading">
                            <h2><em>{t("have")}</em> {t("You")} <span> {t("Finished")}</span> ?!</h2>
                          </div>
                        </div>
                      <div className="row py-5">
                        <div className="col-lg-12 col-md-12">
                        <p className="px-2 py-2">{t("Proof")}</p>

                          <div className="form_box">
                            <input type="file" name="Photo" ref={Photo} className='form-control' />
                          </div>
                        </div>
                        <div className="col-lg-12 col-md-12">
                        <p className="px-2 py-2">{t("Any Comment?")}</p>

                          <div className="form_box">
                            <textarea placeholder={t("Comment")} type="text" name="description" value={bonus_req.description} onChange={onInputChange} className='form-control' ></textarea>
                          </div>
                        </div>
                        <div className="form-button col-md-6 m-auto">
                          <button className="signinbtn" type="submit">{t("Send")}</button>
                        </div>
                      </div>
                      </div>
                    </form>
                  </div>
                </Col>
              )}
            </>
          ))}

      
          <Col
            lg="3"
            md="3"
            sm="3"
            className="blogfilter wow slideInRight"
            data-wow-duration="2s"
            data-wow-delay="0.2s"
          >
          
           
            <div className="thirddivblog">
              <h3 className="thirddivh3">{t("RECENT")}</h3>
              {bonuss.slice(0,3).map((blog) => (
                <Link to={`/Bonus/${blog.id}`}>
              <div
                
                className="lllink"
              >
                <div className="divforthird">
                  <div>
                    <img
                    src={`https://changermoney.com/exchange/uploads/bonuss/${blog.image}`}
                    alt=""
                    />
                  </div>
                  <div>
                    <h3>{t("dir") == "ltr" ? blog.title : blog.title_ar}</h3>

                  </div>
                </div>
              </div>
              </Link>
              ))}
            </div>
           
          </Col>
        </Row>
      </Container>
      <Footer />
    </div>
  );
}

export default BonusRequest;
