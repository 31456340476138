import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import i18n from "./i18n";

const FormComponent = () => {
  const [firstMethod, setFirstMethod] = useState('');
  const [secondMethod, setSecondMethod] = useState('');
  const [amount, setAmount] = useState('');
  const [profitRate, setProfitRate] = useState('');
  const [receivedAmount, setReceivedAmount] = useState('');
  const [minLimit, setMinLimit] = useState('');
  const [maxLimit, setMaxLimit] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [exchanges, setExchanges] = useState([]);
  const [methods, setMethods] = useState([]);

  const [uniqueFirstMethods, setUniqueFirstMethods] = useState([]);
  const [filteredSecondMethods, setFilteredSecondMethods] = useState([]);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const changeLanguage = (language) => {
    i18n.changeLanguage(language, (err, t) => {
      if (err) return console.log("Error loading language:", err);
      // console.log("Language changed to:", language);
    });
  };

  useEffect(() => {
    loadExchange();
    loadMethods();
  }, []);

  const loadExchange = async () => {
    try {
      const result = await axios.get("https://changermoney.com/exchange/dashboard/pay_exchange/readAll.php");
      setExchanges(result.data);

      const uniqueFirsts = Array.from(new Set(result.data.map(item => item.first_method)));
      setUniqueFirstMethods(uniqueFirsts);
    } catch (error) {
      console.error("Error loading exchanges:", error);
    }
  };

  const loadMethods = async () => {
    try {
      const result = await axios.get("https://changermoney.com/exchange/dashboard/pay_methods/readAll.php");
      setMethods(result.data);
    } catch (error) {
      console.error("Error loading Methods:", error);
    }
  };

  const handleFirstMethodChange = (e) => {
    const selectedFirstMethod = e.target.value;
    setFirstMethod(selectedFirstMethod);

    const filteredSecondMethods = exchanges.filter(item => item.first_method === selectedFirstMethod);
    setFilteredSecondMethods(filteredSecondMethods.map(item => item.second_method));

    updateLimits(selectedFirstMethod, secondMethod);
    if (selectedFirstMethod && secondMethod && amount) {
      calculateProfitRate(selectedFirstMethod, secondMethod, amount);
    }
  };

  const handleSecondMethodChange = (e) => {
    const selectedSecondMethod = e.target.value;
    setSecondMethod(selectedSecondMethod);

    updateLimits(firstMethod, selectedSecondMethod);
    if (firstMethod && selectedSecondMethod && amount) {
      calculateProfitRate(firstMethod, selectedSecondMethod, amount);
    }
  };

  const handleAmountChange = (e) => {
    const selectedAmount = e.target.value;
    setAmount(selectedAmount);

    if (firstMethod && secondMethod && selectedAmount) {
      calculateProfitRate(firstMethod, secondMethod, selectedAmount);
    }
  };

  const updateLimits = (firstMethod, secondMethod) => {
    if (firstMethod && secondMethod) {
      const matchingExchanges = exchanges.filter(item => item.first_method === firstMethod && item.second_method === secondMethod);
      if (matchingExchanges.length > 0) {
        const { min_limit, max_limit } = matchingExchanges[0];
        setMinLimit(min_limit);
        setMaxLimit(max_limit);
      } else {
        setMinLimit('');
        setMaxLimit('');
      }
    }
  };

  const calculateProfitRate = (firstMethod, secondMethod, amount) => {
    const exchange = exchanges.find(item => item.first_method === firstMethod && item.second_method === secondMethod);
    if (exchange) {
      let rate;
      let received;

      rate = Number(amount) * Number(exchange.profit);
      received = rate;

      setProfitRate(rate);
      setReceivedAmount(received);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let error = '';
    if (!amount) {
      error = `Must Enter Amount`;
    } else if (receivedAmount < minLimit) {
      error = `Received amount must be greater than or equal to ${minLimit}`;
    } else if (receivedAmount > maxLimit) {
      error = `Received amount must be less than or equal to ${maxLimit}`;
    } else if (!firstMethod || !secondMethod) {
      error = `Must Select A method`;
    }
    setErrorMessage(error);
    if (!error) {
      const userId = localStorage.getItem('id');
      if (userId) {
        // navigate('/Exchange');
        navigate('/CompleteExchange', {
          state: {
            firstMethod: firstMethod,
            secondMethod: secondMethod,
            amount: amount,
            receivedAmount: profitRate,
          }

      })
      } else {
        navigate('/Login');
      }
    }
  };

  return (
    <div>
      <form id="contact-form" onSubmit={handleSubmit} >
        <div className="row">
          <div className="col-lg-12">
            <div className="section-heading">
              <h2><em>{t("Check")}</em> {t("and")} <span>{t("Exchange")}</span></h2>
            </div>
          </div>
          </div>
          <div className="row rowsmall">

          <div className="col-lg-6">
            <fieldset>
              <input type="number" min="0" placeholder='0' value={amount} onChange={handleAmountChange} className='form-control' />
            </fieldset>
          </div>
          <div className="col-lg-6">
            <fieldset>
              <select value={firstMethod} onChange={handleFirstMethodChange} className='form-control'>
                <option value="">{t("Send")}</option>
                {uniqueFirstMethods.map(method => (
                  <option key={method} value={method}>
                    {methods.map(methodd => methodd.id == method ? <> {methodd.name_en}
                      <img src={`https://changermoney.com/exchange/uploads/pay_methods/${methodd.image}`} alt={methodd.name_en} style={{ width: '20px', height: '20px', marginRight: '5px' }} />
                    </> : null)}
                  </option>
                ))}
              </select>
            </fieldset>
          </div>
          <div className="col-lg-6 ">
            <fieldset>
              <input value={profitRate} disabled className='form-control' />
            </fieldset>
          </div>
          <div className="col-lg-6">
            <fieldset>
              <select value={secondMethod} onChange={handleSecondMethodChange} className='form-control'>
                <option value="">{t("Recieve")}</option>
                {filteredSecondMethods.map(method => (
                  <option key={method} value={method}>
                    {methods.map(methodd => methodd.id == method ? methodd.name_en : null)}
                  </option>
                ))}
              </select>
            </fieldset>
          </div>
          <div className="col-lg-12">
            <fieldset>
              {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
            </fieldset>
          </div>
          <div className="col-lg-12">
            <fieldset>
              <button className="orange-button" type="submit">{t("Exchange")}</button>
            </fieldset>
          </div>
        </div>
      </form>
    </div>
  );
};

export default FormComponent;
