import React, { useState } from "react";
import { Link } from "react-router-dom";
import Footer from "./Footer";
import { useTranslation } from "react-i18next";
import { Container, Row, Col } from "react-bootstrap";
import { TiWorld } from "react-icons/ti";
import i18n from "./i18n";
import axios from "axios";
import { FaRegEye, FaRegHandshake } from "react-icons/fa";
import { CiBookmarkCheck } from "react-icons/ci";
import { MdOutlineSecurity } from "react-icons/md";
import { GrTransaction } from "react-icons/gr";
import { MdOutlinePolicy } from "react-icons/md";
import Swal from "sweetalert2";
import ReactStars from "react-rating-stars-component";

function Services() {
  const { t } = useTranslation();
  const [reviews, setReviews] = useState({
    name: "",
    email: "",
    message: "",
    rating: 0,
  });
  const [loading, setLoading] = useState(false);

  const { name, email, message, rating } = reviews;

  const onInputChange = (e) => {
    setReviews({ ...reviews, [e.target.name]: e.target.value });
  };

  const onRatingChange = (newRating) => {
    setReviews({ ...reviews, rating: newRating });
  };

  const submit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      let formData = new FormData();
      formData.append("name", name);
      formData.append("email", email);
      formData.append("message", message);
      formData.append("stars", rating);

      const response = await axios.post(
        "https://changermoney.com/exchange/dashboard/reviews/readAll.php",
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );

      if (response.data) {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Thanks for Your Review",
        });
        setReviews({
          name: "",
          email: "",
          message: "",
          rating: 0,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Failed to submit the review. Please try again later.",
        });
      }
    } catch (error) {
      console.error("Error during Review:", error);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Failed to submit the review. Please try again later.",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <section className="breadcumb-area d-flex align-items-center">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="breacumb-content wow fadeInUp " data-wow-duration="2s">
                <div className="breadcumb-title">
                  <h1>{t("Services")}</h1>
                </div>
                <div className="breadcumb-content-text">
                  <Link to="/">
                    <span>{t("Home")}</span> {t("Services")}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="feature-area featureDiffer style-four pt-100 pb-70 ">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6">
              <div className="feature-single-box wow fadeInLeft animated" data-wow-delay=".3s">
                <div className="feature-box-inner">
                  <div className="feature-icon1">
                    <GrTransaction />
                  </div>
                  <div className="feature-title">
                    <h3>{t("Transaction1")}</h3>
                    <p>{t("Transactions")}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="feature-single-box upper1 wow fadeInRight animated" data-wow-delay=".4s">
                <div className="feature-box-inner">
                  <div className="feature-icon1">
                    <MdOutlineSecurity />
                  </div>
                  <div className="feature-title">
                    <h3>{t("SecurePayment1")}</h3>
                    <p>{t("SecurePayment")}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="feature-single-box upper2 wow fadeInLeft animated" data-wow-delay=".5s">
                <div className="feature-box-inner">
                  <div className="feature-icon1">
                    <TiWorld />
                  </div>
                  <div className="feature-title">
                    <h3>{t("UniversalAccess1")}</h3>
                    <p>{t("UniversalAccess")}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="feature-single-box upper2 wow fadeInRight animated" data-wow-delay=".5s">
                <div className="feature-box-inner">
                  <div className="feature-icon1">
                    <MdOutlinePolicy />
                  </div>
                  <div className="feature-title">
                    <h3>{t("Policy1")}</h3>
                    <p>{t("Policy")}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="aboutsec2">
        <Container>
          <Row className="justify-content-between">
            <Col lg="4" md="4" sm="12" className="divmainsec2 wow fadeInUp " data-wow-duration="2s">
              <div>
                <div className="divsec2222">
                  <FaRegEye />
                </div>
                <h3>{t("OurVision")}</h3>
                <p>- {t("GoodService")}</p>
                <p>- {t("ForCommunity")}</p>
                <p>- {t("LongDevelopment")}</p>
                <p>- {t("HelpPeople")}</p>
              </div>
            </Col>
            <Col lg="4" md="4" sm="12" className="divmainsec2 wow fadeInUp " data-wow-duration="2s">
              <div>
                <div className="divsec2222">
                  <CiBookmarkCheck />
                </div>
                <h3>{t("OurMision")}</h3>
                <p>- {t("ChangeHabits")}</p>
                <p>- {t("BestQuality")}</p>
                <p>- {t("ThinkBigger")}</p>
                <p>- {t("Stability")}</p>
                <p>- {t("Safer")}</p>
              </div>
            </Col>
            <Col lg="4" md="4" sm="12" className="divmainsec2 wow fadeInUp " data-wow-duration="2s">
              <div>
                <div className="divsec2222">
                  <FaRegHandshake />
                </div>
                <h3>{t("Promise")}</h3>
                <p>- {t("SustainableRelationship")}</p>
                <p>- {t("RenewCommitment")}</p>
                <p>- {t("ProvideSolution")}</p>
                <p>- {t("ProfitableRelationship")}</p>
                <p>- {t("AdaptNeeds")}</p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <div className="contact-us section" id="contact">
        <div className="main-title">
          <h2>Add Your Review</h2>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="contact-us-content">
                <div className="row align-items-center">
                  <div className="col-lg-5">
                    <div className="contact-thumb  wow bounceInLeft " data-wow-duration="2s">
                      <img src="images/151.png" alt="" />
                    </div>
                  </div>
                  <div className="col-lg-7 wow bounceInRight " data-wow-duration="2s">
                    <form id="contact-form" onSubmit={(e) => submit(e)}>
                      <div className="row">
                        <div className="col-lg-6 col-md-6">
                          <div className="form_box">
                            <input
                              type="text"
                              name="name"
                              value={name}
                              onChange={(e) => onInputChange(e)}
                              placeholder={t("YourName")}
                            />
                                                   </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                          <div className="form_box">
                            <input
                              type="text"
                              placeholder={t("Yourmail")}
                              name="email"
                              value={email}
                              onChange={(e) => onInputChange(e)}
                            />
                          </div>
                        </div>
                        <div className="col-lg-12 col-md-12">
                          <div className="form_box">
                            <textarea
                              id="message"
                              cols="30"
                              rows="10"
                              placeholder={t("YourMessage")}
                              name="message"
                              value={message}
                              onChange={(e) => onInputChange(e)}
                            ></textarea>
                          </div>
                        </div>
                        <div className="col-lg-12 col-md-12">
                          <div className="form_box">
                            <ReactStars
                              count={5}
                              onChange={onRatingChange}
                              size={24}
                              activeColor="#ffd700"
                              value={rating}
                            />
                          </div>
                        </div>
                        <div className="col-lg-12 col-md-12">
                          <div className="form-button">
                          {loading ? <button type="button">{t("Loading")}</button> : <button type="submit">{t("SendReview")}</button> }

                            
                          </div>
                        </div>
                      </div>
                    </form>

                    <div className="more-info">
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="info-item">
                            <i className="fa fa-phone"></i>
                            <h4>{t("AddReview")}</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}

export default Services;
