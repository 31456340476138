import React, { useEffect, useState } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import i18n from "./i18n";
import Footer from "./Footer";
import Swal from 'sweetalert2';

function Withdraw() {
  const { t } = useTranslation();
  const [withdrawReq, setwithdrawReq] = useState({
    balance: "",
    method_id: "",
    wallet: "",
    comment: "",
  });
  const { balance, method_id, wallet, comment } = withdrawReq;
  const [methods, setMethods] = useState([]);
  const [userBalance, setUserBalance] = useState(0);

  const [userr, setUserr] = useState("");

  useEffect(() => {
    const userId = localStorage.getItem("id");
    setUserr(userId);
    loadMethods();
    loadUserBalance(userId);
  }, []);

  const onInputChange = (e) => {
    setwithdrawReq({ ...withdrawReq, [e.target.name]: e.target.value });
  };

  const loadMethods = async () => {
    try {
      const result = await axios.get("https://changermoney.com/exchange/dashboard/pay_methods/readAll.php");
      setMethods(result.data);
    } catch (error) {
      console.error("Error loading Methods:", error);
    }
  };

  const loadUserBalance = async (userId) => {
    try {
      const result = await axios.get(`https://changermoney.com/exchange/dashboard/balance/readAll.php?user_id=${userId}`);
      setUserBalance(result.data[0].balance);
    } catch (error) {
      console.error("Error loading user balance:", error);
    }
  };

  const submit = async (e) => {
    e.preventDefault();
    if (parseFloat(balance) > userBalance) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Withdrawal amount exceeds available balance.',
      });
      return;
    }else{
      try {
        let formData = new FormData();
        formData.append("user_id", userr);
        formData.append("balance", withdrawReq.balance);
        formData.append("method_id", withdrawReq.method_id);
        formData.append("wallet", withdrawReq.wallet);
        formData.append("comment", withdrawReq.comment);
        const response = await axios.post(
          "https://changermoney.com/exchange/dashboard/withdraw/readAll.php",
          formData,
          {
            headers: { "Content-Type": "multipart/form-data" },
          }
        );
  
        if (response.data) {
          Swal.fire({
            icon: 'success',
            title: 'Success',
            text: 'Thanks, we will respond to you.',
          }).then(() => {
            // Optionally redirect or do something else after success
            // console.log(response);
          });
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Failed to submit. Please try again later.',
          });
        }
      } catch (error) {
        console.error("Error during submission:", error);
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Failed to submit the ticket. Please try again later.',
        });
      }
    }

  };

  return (
    <>
      <section className="main-banner" id="top">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-9 col-md-9">
              <div className="singles-contacts-box">
                <form onSubmit={submit} id="second-form">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="section-heading">
                        <h2><em>{t("Withraw")}</em> {t("fromYour")} <span> {t("Balance")}</span></h2>
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12">
                      <div className="form_box">
                        <input
                          placeholder={t("Balance")}
                          type="number"
                          name="balance"
                          value={withdrawReq.balance}
                          onChange={onInputChange}
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12">
                      <div className="form_box">
                        <input
                          placeholder={t("Wallet")}
                          type="text"
                          name="wallet"
                          value={withdrawReq.wallet}
                          onChange={onInputChange}
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12">
                      <div className="form_box">
                        <select value={withdrawReq.method_id}
                        name="method_id"  onChange={onInputChange} className='form-control'>
                          <option value="">{t("GetTo")}</option>
                          {methods.map(method => (
                            <option key={method.id} value={method.id}>
                              {method.name_en}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12">
                      <div className="form_box">
                        <input
                          placeholder={t("Comment")}
                          type="text"
                          name="comment"
                          value={withdrawReq.comment}
                          onChange={onInputChange}
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="form-button col-md-6 m-auto">
                      <button className="signinbtn" type="submit">
                        {t("Send")}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default Withdraw;
