import React, { useEffect, useState } from "react";
import { Col, Container, Row, Table } from "react-bootstrap";
import { Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import Footer from "./Footer";
import { useTranslation } from "react-i18next";
import i18n from "./i18n";
import { FaStar } from "react-icons/fa6";
import FormComponent from "./FormComponent";
import { PiUsersThreeDuotone } from "react-icons/pi";
import axios from "axios";
import { Link } from "react-router-dom";

function Tickets() {
    const [tickets, setTicket] = useState([]);
    const [userId, setUserId] = useState(0);
    const { t } = useTranslation();

    const changeLanguage = (language) => {
        i18n.changeLanguage(language, (err, t) => {
          if (err) return console.log("Error loading language:", err);
        //   console.log("Language changed to:", language);
        });
      };
    useEffect(() => {
        loadTicket();
        var userId = localStorage.getItem("id");
        if (!userId) {
            window.location.href = '/';
        }
        setUserId(userId);

    }, []);

    const loadTicket = async () => {
        const result = await axios.get(
            "https://changermoney.com/exchange/dashboard/tickets/readAll.php"
        );
        setTicket(result.data);
    };

    const userTickets = tickets.filter(ticket => ticket.user_id == userId);

    return (
        <>

            <section className="infos infodifferr" id="infos">
                <div className="container">
                    <Table>
                        <thead>
                            <tr className=" headerHome">

                                <th>
                                    <div className="change">
                                        <div className="change-thumb">

                                            <p><span>{t("Image")}</span></p>
                                        </div>
                                    </div>
                                </th>

                                <th>
                                    <div className="change">
                                        <div className="change-thumb">
                                            <p><span> {t("Name")}</span></p>
                                        </div>
                                    </div>
                                </th>

                                <th>
                                    <div className="change">
                                        <div className="change-thumb">
                                            <p><span>{t("EMailAddress")}</span></p>
                                        </div>
                                    </div>
                                </th>


                                <th>
                                    <div className="change">
                                        <div className="change-thumb">
                                            <p><span>{t("Subject")}</span></p>

                                        </div>
                                    </div>

                                </th>


                                <th>
                                    <div className="change">
                                        <div className="change-thumb">
                                            <p><span>{t("Status")}</span></p>

                                        </div>
                                    </div>
                                </th>


                                <th>
                                    <div className="change">
                                        <div className="change-thumb">
                                            <p><span>{t("Response")}</span></p>
                                        </div>
                                    </div>
                                </th>
                                <th>
                                    <div className="change">
                                        <div className="change-thumb">
                                            <p><span>{t("Message")}</span></p>
                                        </div>
                                    </div>
                                </th>



                            </tr>

                        </thead>
                        <tbody>
                            {
                                userTickets.length === 0 ? (
                                    <p style={{ textAlign: "center" }} className="my-5">{t("NoPreviousTickets")}</p>
                                ) : (
                                    userTickets.map((ticket) => (


                                        <tr>
                                            <td>
                                                <div className="change">
                                                    <div className="change-thumb">
                                                        <img src={`https://changermoney.com/exchange/uploads/tickets/${ticket.image}`} alt="" height="100px" />
                                                    </div>
                                                </div>
                                            </td>



                                            <td>
                                                <div className="amrcat-cap-content">
                                                    <p><span>{ticket.name}</span></p>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="amrcat-cap-content">
                                                    <p><span>{ticket.email}</span></p>
                                                </div>
                                            </td>

                                            <td>
                                                <div className="amrcat-cap-content">
                                                    <p>{ticket.subject}</p>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="amrcat-cap-content">
                                                    <p><span>{ticket.status == 0 ? 'pending' : ticket.status == 1 ? 'processing' : ticket.status == 2 ? 'completed' : null}</span></p>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="amrcat-cap-content">
                                                    <p><Link to={`/TicketResponse/${ticket.id}`}>{t("Response")}</Link></p>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="amrcat-cap-content">
                                                    <p>{ticket.message}</p>
                                                </div>
                                            </td>
                                        </tr>


                                    )))
                            }
                        </tbody>
                    </Table>

                </div>
            </section>

            <Footer />
        </>
    );
}

export default Tickets;
