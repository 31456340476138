import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import i18n from "./i18n";
import { useTranslation } from "react-i18next";

const UpdateUserData = () => {
  const [user, setUser] = useState({
    f_name: "",
    username: "",
    s_name: "",
    address: "",
    phone: ""
  });
  const { f_name, username, s_name, address, phone } = user;
  const [userId, setUserId] = useState(localStorage.getItem("id"));
  const { t } = useTranslation();

  const changeLanguage = (language) => {
    i18n.changeLanguage(language, (err, t) => {
      if (err) return console.log("Error loading language:", err);
    });
  };

  useEffect(() => {
    const userId = localStorage.getItem("id");
    if (!userId) {
      window.location.href = "/";
      return;
    }
    setUserId(userId);
    loadUser(userId);
  }, []);

  const loadUser = async (id) => {
    try {
      const result = await axios.get(`https://changermoney.com/exchange/dashboard/users/readAll.php?id=${userId}`);
      setUser(result.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const onInputChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    let formData = new FormData();
    formData.append("f_name", f_name);
    formData.append("username", username);
    formData.append("s_name", s_name);
    formData.append("address", address);
    formData.append("phone", phone);

    try {
      await axios({
        method: "post",
        url: `https://changermoney.com/exchange/dashboard/users/readAll.php?id=${userId}`,
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      });
      alert("User data successfully updated.");
      window.localStorage.setItem("name", username);
      

    } catch (error) {
      console.error("Error updating data:", error);
    }
  };

  return (
    <section className="main-banner" id="top">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-9 col-md-9">
            <div className="singles-contacts-box">
              <div id="second-form" className="invitioncard updateprofilee py-5 text-center">
                <form onSubmit={onSubmit}>
                  <p > {t("FirstName")}</p>
                  <input
                    type="text"
                    placeholder={t("EnterFirstName")}
                    name="f_name"
                    value={f_name}
                    required
                    onChange={onInputChange}
                  />
                  <p > {t("LastName")}</p>
                  <input
                    type="text"
                    placeholder={t("EnterLastName")}
                    name="s_name"
                    value={s_name}
                    required
                    onChange={onInputChange}
                  />
                  <p > {t("Username")}</p>
                  <input
                    type="text"
                    placeholder={t("EnterUsername")}
                    name="username"
                    value={username}
                    required
                    onChange={onInputChange}
                  />
                  <p > {t("Address")}</p>
                  <input
                    type="text"
                    placeholder={t("EnterAddress")}
                    name="address"
                    value={address}
                    required
                    onChange={onInputChange}
                  />
                  <p > {t("Phone")}</p>
                  <input
                    type="text"
                    placeholder={t("EnterPhone")}
                    name="phone"
                    value={phone}
                    required
                    onChange={onInputChange}
                  />
                  <button type="submit">{t("Update")}</button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default UpdateUserData;
