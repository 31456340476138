import React, { useEffect, useState, useRef } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Footer from "./Footer";
import { useTranslation } from "react-i18next";
import i18n from "./i18n";
import axios from "axios";
import { TiWorld } from "react-icons/ti";
import { MdOutlineAccountBalance } from "react-icons/md";
import { TfiCup } from "react-icons/tfi";
import { CgArrowsExchangeAlt } from "react-icons/cg";
import { VscActivateBreakpoints } from "react-icons/vsc";
import { Link } from "react-router-dom";

function Dashboard() {
    const [methods, setMethod] = useState([]);
    const [userId, setUserId] = useState(localStorage.getItem("id") || 0);
    const [balance, setBalance] = useState([]);
    const [reedmedBalance, setReedmedBalance] = useState([]);

    const [points, setPoints] = useState([]);
    const [exchangesPending, setExchangesPending] = useState([]);
    const [exchangesApproved, setExchangesApproved] = useState([]);
    const [exchangesProcessing, setExchangesProcessing] = useState([]);
    const [exchangesCompleted, setExchangesCompleted] = useState([]);
    const [exchangesRefused, setExchangesRefused] = useState([]);
    const [exchangesCanceled, setExchangesCanceled] = useState([]);
    const [exchangesRefunded, setExchangesRefunded] = useState([]);
    const [waitingApproved, setWaitingApproved] = useState([]);

    // const [pointsAfflication, setPointsAfflication] = useState([]);
    // const [pointsBonus, setPointsBonus] = useState([]);
    // const [pointsExchange, setPointsExchange] = useState([]);
    const [exchanges, setExchanges] = useState([]);
    const [levels, setLevels] = useState([]);
    const [userLevel, setUserLevel] = useState({ title_en: "No Level", title_ar: "بدون ترقيه" });
    const [userExchangess, setUserExchanges] = useState([]);

    const { t } = useTranslation();
    const changeLanguage = (language) => {
        i18n.changeLanguage(language, (err, t) => {
            if (err) return console.log("Error loading language:", err);
            // console.log("Language changed to:", language);
        });
    };

    useEffect(() => {
        if (!userId) {
            window.location.href = '/';
        } else {
            loadBalance();
            loadPoints();
            loadExchangePending();
            loadExchangeProcessing();
            loadExchangeCompleted();
            loadExchangeApproved();
            loadExchangeRefused();
            loadExchangeCanceled();
            loadExchangeRefunded();
            // loadPointsAfflication();
            // loadPointsBonus();
            // loadPointsExchange();
            loadExchangeWaitingApproved()
            loadExchange();
            loadLevels()
            loadReedmedBalance()
            loadUserExchanges();

        }
    }, [userId]);

    const loadBalance = async () => {
        try {
            const result = await axios.get(
                "https://changermoney.com/exchange/dashboard/balance/readAll.php"
            );
            setBalance(result.data);
        } catch (error) {
            // console.error("Error loading balance:", error);
            setBalance([]);

        }
    };

    const loadReedmedBalance = async () => {
        try {
            const result = await axios.get(
                `https://changermoney.com/exchange/dashboard/balance/readAll.php?reedmed_balance=1&&user_id=${userId}`
            );
            setReedmedBalance(result.data);
        } catch (error) {
            // console.error("Error loading balance:", error);
            setReedmedBalance([]);

        }
    };
    const loadPoints = async () => {
        try {
            const result = await axios.get(
                "https://changermoney.com/exchange/dashboard/points/readAll.php"
            );
            setPoints(result.data);
        } catch (error) {
            // console.error("Error loading points:", error);
            setPoints([]);

        }
    };
    // const loadPointsAfflication = async () => {
    //     try {
    //         const result = await axios.get(
    //             "https://changermoney.com/exchange/dashboard/points/readAll.php?afflication=1"
    //         );
    //         setPointsAfflication(result.data);
    //     } catch (error) {
    //         console.error("Error loading points:", error);
    //     }
    // };
    // const loadPointsBonus = async () => {
    //     try {
    //         const result = await axios.get(
    //             "https://changermoney.com/exchange/dashboard/points/readAll.php?bonus=1"
    //         );
    //         setPointsBonus(result.data);
    //     } catch (error) {
    //         console.error("Error loading points:", error);
    //     }
    // };
    // const loadPointsExchange = async () => {
    //     try {
    //         const result = await axios.get(
    //             "https://changermoney.com/exchange/dashboard/points/readAll.php?exchange=1"
    //         );
    //         setPointsExchange(result.data);
    //     } catch (error) {
    //         console.error("Error loading points:", error);
    //     }
    // };
    const loadExchangePending = async () => {
        try {
            const result = await axios.get(
                `https://changermoney.com/exchange/dashboard/exchanges/readAll.php?status=0&&user_id=${userId}`
            );
            setExchangesPending(result.data);
        } catch (error) {
            // console.error("Error loading pending exchanges:", error);
            setExchangesPending([]);

        }
    };
    const loadExchangeWaitingApproved = async () => {
        try {
            const result = await axios.get(
                `https://changermoney.com/exchange/dashboard/exchanges/readAll.php?approved=0&&user_id=${userId}`
            );
            setWaitingApproved(result.data);
        } catch (error) {
            // console.error("Error loading pending exchanges:", error);
            setWaitingApproved([]);

        }
    };
    const loadExchange = async () => {
        try {
            const result = await axios.get(
                "https://changermoney.com/exchange/dashboard/exchanges/readAll.php"
            );
            setExchanges(result.data);
        } catch (error) {
            // console.error("Error loading pending exchanges:", error);
            setExchanges([]);

        }
    };
    const loadExchangeProcessing = async () => {
        try {
            const result = await axios.get(
                `https://changermoney.com/exchange/dashboard/exchanges/readAll.php?status=1&&user_id=${userId}`
            );
            setExchangesProcessing(result.data);
        } catch (error) {
            // console.error("Error loading processing exchanges:", error);
            setExchangesProcessing([]);

        }
    };

    const loadExchangeCompleted = async () => {
        try {
            const result = await axios.get(
                `https://changermoney.com/exchange/dashboard/exchanges/readAll.php?status=2&&user_id=${userId}`
            );
            setExchangesCompleted(result.data);
        } catch (error) {
            // console.error("Error loading completed exchanges:", error);
            setExchangesCompleted([]);

        }
    };

    const loadExchangeApproved = async () => {
        try {
            const result = await axios.get(`https://changermoney.com/exchange/dashboard/exchanges/readAll.php?approved=1&&user_id=${userId}`);
            setExchangesApproved(result.data);
        } catch (error) {
            // console.error("Error loading approved exchanges:", error);
            setExchangesApproved([]);

        }
    };

    const loadExchangeRefused = async () => {
        try {
            const result = await axios.get(
                "https://changermoney.com/exchange/dashboard/exchanges/readAll.php?refused=2"
            );
            setExchangesRefused(result.data);
        } catch (error) {
            // console.error("Error loading refused exchanges:", error);
            setExchangesRefused([]);

        }
    };

    const loadExchangeCanceled = async () => {
        try {
            const result = await axios.get(
                "https://changermoney.com/exchange/dashboard/exchanges/readAll.php?canceled=1"
            );
            setExchangesCanceled(result.data);
        } catch (error) {
            // console.error("Error loading canceled exchanges:", error);
            setExchangesCanceled([]);

        }
    };

    const loadExchangeRefunded = async () => {
        try {
            const result = await axios.get(
                "https://changermoney.com/exchange/dashboard/exchanges/readAll.php?refunded=1"
            );
            setExchangesRefunded(result.data);
        } catch (error) {
            // console.error("Error loading refunded exchanges:", error);
            setExchangesRefunded([]);

        }
    };



    const userBalance = balance.filter(balance => balance.user_id == userId);
    const userReedmedBalance = reedmedBalance.filter(balancee => balancee.user_id == userId);
    const userPoints = points.filter(points => points.user_id == userId);

    const userExchangesPending = exchangesPending.filter(exchanges => exchanges.user_id == userId);
    const userExchangesProcessing = exchangesProcessing.filter(exchanges => exchanges.user_id == userId);
    const userExchangesCompleted = exchangesCompleted.filter(exchanges => exchanges.user_id == userId);
    const userExchangesApproved = exchangesApproved.filter(exchanges => exchanges.user_id == userId);
    const userExchangesWaitingApproved = waitingApproved.filter(exchanges => exchanges.user_id == userId);

    const userExchangesRefused = exchangesRefused.filter(exchanges => exchanges.user_id == userId);
    const userExchangesCanceled = exchangesCanceled.filter(exchanges => exchanges.user_id == userId);
    const userExchangesRefunded = exchangesRefunded.filter(exchanges => exchanges.user_id == userId);

    // const userPointsAfflication = pointsAfflication.filter(points => points.user_id == userId);
    // const userPointsBonus = pointsBonus.filter(points => points.user_id == userId);
    // const userPointsExchange = pointsExchange.filter(points => points.user_id == userId);
    const userExchanges = exchanges.filter(exchanges => exchanges.user_id == userId);

    const loadLevels = async () => {
        try {
            const result = await axios.get("https://changermoney.com/exchange/dashboard/levels/readAll.php");
            setLevels(result.data);
            determineUserLevel(result.data);
        } catch (error) {
            console.error("Error loading levels:", error);
        }
    };
    const determineUserLevel = (levels) => {
        // Fetch approved levels for the user
        axios.get(`https://changermoney.com/exchange/dashboard/level_req/readAll.php?user_id=${userId}`)
            .then(response => {
                const approvedLevels = response.data;

                if (approvedLevels.length > 0) {
                    // Get the level with the least orderr value
                    const leastOrderLevel = levels.reduce((minLevel, currentLevel) => {
                        const levelInReq = approvedLevels.find(level => level.level_id === currentLevel.id);
                        if (levelInReq && (minLevel === null || currentLevel.orderr < minLevel.orderr)) {
                            return currentLevel;
                        }
                        return minLevel;
                    }, null);

                    if (leastOrderLevel) {
                        setUserLevel({
                            title_en: leastOrderLevel.title_en,
                            title_ar: leastOrderLevel.title_ar
                        });
                    }
                }
            })
            .catch(error => console.error("Error fetching approved levels:", error));
    };

    const loadUserExchanges = async () => {
        try {
            const result = await axios.get(
                `https://changermoney.com/exchange/dashboard/exchanges/readAll.php?userId=${userId}`
            );
            setUserExchanges(result.data);
        } catch (error) {
            console.error("Error loading user exchanges:", error);
        }
    };

    const calculateTotalExchanges = () => {
        return userExchangess.reduce((total, exchange) => total + parseFloat(exchange.equal_to_dollar), 0);
    };
    const userTotalExchanges = calculateTotalExchanges();

    return (
        <>
            <section className="feature-area featuress dashboard">
                <Container>
                    <Row>
                        <Col lg="12" md="12">
                            <div className="sections-titl wow fadeInUp">
                  
                                <div className="main-title">
                                    <h2>{t("Dashboard")}</h2>
                                </div>
                                <div className="main-title">
                  <h2>#{userId}</h2>
                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="6" md="6">
                            <Link to="/Profile">
                                <div className="single-feature-box  wow fadeInUp" data-wow-duration="2s" >
                                    <div className="feature-thumb">
                                        <MdOutlineAccountBalance />
                                    </div>
                                    <div className="feature-title">
                                        <h3>{t("CurrentBalance")}</h3>
                                        {userBalance.length === 0 ? (
                                            <p style={{ textAlign: "center" }}>0</p>
                                        ) : (
                                            userBalance.map((balance) => (
                                                <p>$ {balance.balance}</p>
                                            )))}
                                    </div>
                                </div>
                            </Link>
                        </Col>
                        <Col lg="6" md="6">
                            <Link to="/ChangePointsToDollars">

                                <div className="single-feature-box  wow fadeInUp" data-wow-duration="2s" >
                                    <div className="feature-thumb">
                                        <VscActivateBreakpoints />
                                    </div>
                                    <div className="feature-title">
                                        <h3>{t("Points")}</h3>
                                        {userPoints.length === 0 ? (
                                            <p style={{ textAlign: "center" }}>0</p>
                                        ) : (
                                            userPoints.map((points) => (
                                                <p> {points.points}</p>
                                            )))}
                                    </div>
                                </div>
                            </Link>
                        </Col>
                        <Col lg="3" md="6">
                        <Link to="/Profile">

                            <div className="single-feature-box  wow fadeInUp" data-wow-duration="2s">
                                <div className="feature-thumb">
                                    <CgArrowsExchangeAlt />
                                </div>
                                <div className="feature-title">
                                    <h3>{t("NumberOfExchanges")}</h3>
                                    <p>
                                        {userExchanges.length}
                                    </p>
                                </div>
                            </div>
                            </Link>
                        </Col>
                        <Col lg="3" md="6">
                        <Link to="/Profile">

                            <div className="single-feature-box  wow fadeInUp" data-wow-duration="2s">
                                <div className="feature-thumb">
                                    <MdOutlineAccountBalance />
                                </div>
                                <div className="feature-title">
                                    <h3>{t("TotalMoneyExchanged")}</h3>
                                    <p>
                                       $ {userTotalExchanges}
                                    </p>
                                </div>
                            </div>
                            </Link>
                        </Col>
                        <Col lg="3" md="6">
                            <Link to="/ReedmedTransactions">

                                <div className="single-feature-box  wow fadeInUp" data-wow-duration="2s" >
                                    <div className="feature-thumb">
                                        <MdOutlineAccountBalance />
                                    </div>
                                    <div className="feature-title">
                                        <h3>{t("ReedmedBalance")}</h3>
                                        {userReedmedBalance.length === 0 ? (
                                            <p style={{ textAlign: "center" }}>0</p>
                                        ) : (
                                            userReedmedBalance.map((balancee) => (
                                                <p>$ {balancee.balance}</p>
                                            )))}
                                    </div>
                                </div>
                            </Link>

                        </Col>
                        <Col lg="3" md="6">
                            <Link to="/Levels">
                                <div className="single-feature-box  wow fadeInUp" data-wow-duration="2s" >
                                    <div className="feature-thumb">
                                        <TfiCup />

                                    </div>
                                    <div className="feature-title">
                                        <h3>{t("Level")}</h3>
                                        {/* <p>{i18n.language === "ar" ? userLevel.title_ar : userLevel.title_en}</p> */}
                                        <p>{i18n.language === "ar" ? userLevel.title_ar : userLevel.title_en}</p>

                                    </div>
                                </div>
                            </Link>
                        </Col>
         
                        <Col lg="3" md="6">
                            <Link to="/WaitingApprovedExchanges">

                                <div className="single-feature-box  wow fadeInUp" data-wow-duration="2s">
                                    <div className="feature-thumb">
                                        <CgArrowsExchangeAlt />


                                    </div>
                                    <div className="feature-title">
                                        <h3>{t("WaitingForApprovel")}</h3>
                                        <p>
                                            {userExchangesWaitingApproved.length}
                                        </p>
                                    </div>
                                </div>
                            </Link>

                        </Col>
                        <Col lg="3" md="6">
                            <Link to="/ApprovedExchanges">

                                <div className="single-feature-box  wow fadeInUp" data-wow-duration="2s">
                                    <div className="feature-thumb">
                                        <CgArrowsExchangeAlt />
                                    </div>
                                    <div className="feature-title">
                                        <h3>{t("ApprovedExchange")}</h3>
                                        <p>
                                            {userExchangesApproved.length}
                                        </p>

                                    </div>
                                </div>
                            </Link>

                        </Col>
                        <Col lg="3" md="6">
                            <Link to="/PendingTransactions">

                                <div className="single-feature-box  wow fadeInUp" data-wow-duration="2s">
                                    <div className="feature-thumb">
                                        <CgArrowsExchangeAlt />


                                    </div>
                                    <div className="feature-title">
                                        <h3>{t("PendingExchange")}</h3>
                                        <p>
                                            {userExchangesPending.length}
                                        </p>
                                    </div>
                                </div>
                            </Link>

                        </Col>
                        <Col lg="3" md="6">
                            <Link to="/ProcessingTransactions">

                                <div className="single-feature-box  wow fadeInUp" data-wow-duration="2s">
                                    <div className="feature-thumb">
                                        <CgArrowsExchangeAlt />


                                    </div>
                                    <div className="feature-title">
                                        <h3>{t("ProcessingExchange")}</h3>
                                        <p>
                                            {userExchangesProcessing.length}
                                        </p>
                                    </div>
                                </div>
                            </Link>

                        </Col>
                        <Col lg="3" md="6">
                            <Link to="/CompletedTransactions">

                                <div className="single-feature-box  wow fadeInUp" data-wow-duration="2s">
                                    <div className="feature-thumb">
                                        <CgArrowsExchangeAlt />


                                    </div>
                                    <div className="feature-title">
                                        <h3>{t("CompletedExchange")}</h3>
                                        <p>
                                            {userExchangesCompleted.length}
                                        </p>
                                    </div>
                                </div>
                            </Link>

                        </Col>
                        {/* <Col lg="3" md="6">
                            <div className="single-feature-box  wow fadeInUp" data-wow-duration="2s">
                                <div className="feature-thumb">
                                    <VscActivateBreakpoints />
                                </div>
                                <div className="feature-title">
                                    <h3>{t("afflicationpoints")}</h3>
                                    {userPointsAfflication.length === 0 ? (
                                        <p style={{ textAlign: "center" }}>0</p>
                                    ) : (
                                        userPointsAfflication.map((points) => (
                                            <p> {points.points}</p>
                                        )))}
                                </div>
                            </div>
                        </Col>
                        <Col lg="3" md="6">
                            <div className="single-feature-box  wow fadeInUp" data-wow-duration="2s">
                                <div className="feature-thumb">
                                    <VscActivateBreakpoints />
                                </div>
                                <div className="feature-title">
                                    <h3>{t("Bonuspoints")}</h3>
                                    {userPointsBonus.length === 0 ? (
                                        <p style={{ textAlign: "center" }}>0</p>
                                    ) : (
                                        userPointsBonus.map((points) => (
                                            <p> {points.points}</p>
                                        )))}
                                </div>
                            </div>
                        </Col>
                        <Col lg="3" md="6">
                            <div className="single-feature-box  wow fadeInUp" data-wow-duration="2s">
                                <div className="feature-thumb">
                                    <VscActivateBreakpoints />
                                </div>
                                <div className="feature-title">
                                    <h3>{t("Exchangepoints")}</h3>
                                    {userPointsExchange.length === 0 ? (
                                        <p style={{ textAlign: "center" }}>0</p>
                                    ) : (
                                        userPointsExchange.map((points) => (
                                            <p> {points.points}</p>
                                        )))}
                                </div>
                            </div>
                        </Col> */}
                        <Col lg="3" md="6">
                        <Link to="/CanceledTransactions">
                            <div className="single-feature-box  wow fadeInUp" data-wow-duration="2s">
                                <div className="feature-thumb">
                                    <CgArrowsExchangeAlt />
                                </div>
                                <div className="feature-title">
                                    <h3>{t("CanceledExchange")}</h3>
                                    <p>
                                        {userExchangesCanceled.length}
                                    </p>
                                </div>
                            </div>
                            </Link>
                        </Col>
                        <Col lg="3" md="6">
                        <Link to="/RefundedTransactions">

                            <div className="single-feature-box  wow fadeInUp" data-wow-duration="2s">
                                <div className="feature-thumb">
                                    <CgArrowsExchangeAlt />
                                </div>
                                <div className="feature-title">
                                    <h3>{t("RefundedExchange")}</h3>
                                    <p>{userExchangesRefunded.length}</p>
                                </div>
                            </div>
                            </Link>
                        </Col>
                        <Col lg="3" md="6">
                        <Link to="/RefusedTransactions">

                            <div className="single-feature-box  wow fadeInUp" data-wow-duration="2s">
                                <div className="feature-thumb">
                                    <CgArrowsExchangeAlt />
                                </div>
                                <div className="feature-title">
                                    <h3>{t("RefusedExchange")}</h3>
                                    <p>
                                        {userExchangesRefused.length}
                                    </p>
                                </div>
                            </div>
                            </Link>
                        </Col>
                      
                    </Row>
                </Container>
            </section>


            <Footer />
        </>
    );
}

export default Dashboard;
