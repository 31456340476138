// i18n.js
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  en: {
    translation: {
      dir: "ltr",
      Home: "Home",
      About: "About",
      contact: "Contact US",
      FAQ: "FAQs",
      profile: "Profile",
      logout: "Log Out",
      Services: "Services",
      exchangeAgency: "EXCHANGE CURRENCY AGENCY",
      Discover: "Discover More",
      Check: "Check our Exchanges",
      Enter: "Enter Your Amount",
      BeeChanger: "Changer Money",
      BeeChangerP: "Welcome to our cutting-edge money transfer platform , where convenience meets security. With seamless transactions between all major banks, we pride ourselves on being the simplest, safest, and most secure option available. Plus, we offer competitive rates to ensure you get the best value for your money.",
      Exchange: "Exchange",
      from: "From",
      to: "To",
      provide: "We Provide ",
      dservices: "Different Services",
      Features: "Features",
      Agency: "For Our Agency",
      platform: "Our platform ensures ease and security, providing you with the best deals and competitive rates.",
      ProfitableExchange: "Profitable Exchange",
      High: "High Level Reliabilty",
      Economic: "Economic Exchange",
      Withdraw: "Withdraw referrals",
      WORKS: "How Changer Money works",
      Quality: "We Take Care Quality",
      Qualitatively: "Qualitatively  change the Currency online",
      somethingWrong: "if something went wrong , we will always refund your money",
      exchangeguarantee: "100% exchange guarantee",
      Numerous: " Numerous positive reviews confirm the high quality of our work",
      loyalrates: " we always have loyal rates , we are always open to cooperation , please contact",
      providereliabilty: "we provide not only reliabilty and the best rate , but also for quick exchange",
      populardestinations: "we make exchanges in the most popular destinations , you can always exchange your funds",
      exchangedirections: "a lot of exchange directions",
      FastExchange: "Fast Exchange",
      Reviews: "Reviews",
      and: "&",
      Opinions: "Opinions",
      feedback: "feedback reinforces our commitment to being the best in the industry, ensuring every exchange is smooth, secure, and beneficial.",
      ContactUs: "Contact Us ",
      Touch: "Get In Touch",
      YourName: " Name",
      YourSurname: " Last Name",
      YourUsername: " UserName",
      Yourmail: " E-mail",
      YourMessage: " Message",
      Subject: "Subject",
      SendMessageNow: "Send Message Now",
      OurVision: "Our Vision",
      GoodService: "Good Service",
      ForCommunity: "For Community",
      LongDevelopment: "Long Term Development",
      SavePlanet: "Save Our Planet",
      HelpPeople: "Help People",
      OurMision: "Our Mision",
      ChangeHabits: "Change The Habits",
      BestQuality: "Best Quality",
      ThinkBigger: "Think Big Do Bigger",
      Stability: "Stability & competence",
      Safer: "Safer & Better Life",
      Promise: "Our Promise",
      SustainableRelationship: "Sustainable Relationship",
      RenewCommitment: "Renew Commitment",
      ProvideSolution: "Provide The Best Solution",
      ProfitableRelationship: "Profitable Relationship",
      AdaptNeeds: "Adapt With People's Needs",
      Transaction: "Transaction",
      Support: "Support",
      Easy: "Easy",
      Fast: "Fast",
      Policy: "Policy",
      Security: "Security",
      OurContacts: "Our Contacts",
      AddressInfo: "Address Info",
      Cairo: " Cairo , Egypt",
      PhoneCalls: "Phone Calls",
      EMailAddress: "E-Mail Address",
      MostAsked: "Most Frequently Asked",
      Questions: "Questions",
      Here: "Here",
      StepsSuccess: "Our 4 Steps To Success",
      HappyClients: "Happy Clients",
      quickanswers: "We offer quick answers to common questions about our services",
      SendReview: "Send Review",
      AddReview: "Add Your Review",
      Transactions: "Every transaction is executed with precision and care, ensuring a seamless and secure process from start to finish. Trust us to handle your exchanges efficiently and reliably."
      , SecurePayment: "Our platform guarantees secure payment methods, providing peace of mind for all your transactions. We prioritize your safety and privacy, ensuring that your financial information is always protected."
      , UniversalAccess: "Experience universal access with our platform, enabling you to perform transactions from anywhere in the world. Our services are designed to be convenient and accessible, no matter where you are."
      , Policy: "Our policies are transparent and customer-focused, designed to ensure a fair and secure experience for all users. We are committed to upholding the highest standards of integrity and reliability."
      , Transaction1: "Transaction",
      SecurePayment1: "Secure Payment",
      UniversalAccess1: "Universal Access",
      Policy1: "Policy",
      terms: "terms",
      Follow: "Follow",
      Password: "Password",
      Donthave: "Dont have an Account ?",
      CreateNow: "Create Now",
      YourPhone: "Your Phone",
      Register: "Register",
      HaveAccount: " Have an Account ?",
      LoginHere: "Login Here",
      SignIn: "SignIn",
      Send: "Send",
      ReceiveAmount: "Receive Amount",
      Receive: "Receive",
      Next: "Next",
      Wallet: "Wallet",
      ReceiveWallet: "Receive Wallet",
      Proofpayment: "Proof of payment",
      ConfirmPassword: "Confirm Password",
      TransactionsNav: "Transactions",
      Blogs: "Blogs",
      Bonus: "Bonus",
      Dashbard: "Dashbard",
      Withdraw: "Withdraw",
      NewWithdraw: "New Withdraw",
      AllWithdraw: "All Withdraw",
      Tickets: "Tickets",
      NewTicket: " New Ticket",
      PendingTransactions: "Pending Transactions",
      ProcessingTransactions: "Processing Transactions",
      CompletedTransactions: "Completed Transactions",
      CanceledTransactions: "Canceled Transactions",
      RefundedTransactions: "Refunded Transactions",
      AllTransactions: "All Transactions",
      InviteFriends: "Invite Friends",
      Afflication: "Afflication",
      Dashboard: "Dashboard",
      Exchanger: "Exchanger",
      SendFrom: "Send From",
      SendAmount: "Send Amount",
      GetTo: "Get To",
      GetAmount: "Get Amount",
      Date: "Date",
      NoPrevioustransactions: "No Previous Transactions",
      RECENT: "RECENT",
      ReadMore: "Read More",
      Search: "Search",
      Withraw: "Withraw",
      fromYour: "from Your",
      Balance: "Balance",
      CurrentBalance: "Current Balance",
      Points: "Points",
      Level: "Level",
      PendingExchange: "Pending Exchange",
      ApprovedExchange: "Approved Exchange",
      ProcessingExchange: "Processing Exchange",
      CompletedExchange: "Completed Exchange",
      afflicationpoints: "Afflication points",
      Bonuspoints: "Bonus points",
      Exchangepoints: "Exchange points",
      CanceledExchange: "Canceled Exchange",
      RefundedExchange: "Refunded Exchange",
      RefusedExchange: "Refused Exchange",
      TransactionID: "Transaction ID",
      TimeCanbeCanceled: "Time Can be Canceled",
      PaymentProof: "Payment Proof",
      Amount: "Amount",
      Comment: "Comment",
      Status: "Status",
      NoPreviousWithdraw: "No Previous Withdraw",
      Reserve: "Reserve",
      YourTicket: "Your Ticket",
      invitionlink: "invition Link",
      Generate: "Generate",
      CopyLink: "Copy Link",
      Image: "Image",
      Name: "Name",
      NoPreviousTickets: "No Previous Tickets",
      userinvitedid: "User invited id",
      userinvitedusername: "User invited username",
      codeinvitedwith: "Code invited with",
      GotBonus: "Got Bonus",
      NoPreviousAfflications: "No Previous Afflications",
      Message: "Message",
      topNav: "For technical support or to complete the transfer faster, please contact us via WhatsApp",
      Recieve: "Recieve",
      TotalExchangesNumber: "Number Of Exchanges",
      TotalExchangesBalance: "Total Money Exchanged ",
      TotalExchangesNumberReq: "Number Of Exchanges Required",
      TotalExchangesBalanceReq: "Total Money Exchanged Required",
      Other: "Other",
      UpdateProfile: "Update Profile",
      Pages: "Pages",
      Company: "Company",
      NumberOfExchanges:"Number Of Exchanges",
      TotalMoneyExchanged :"Total Money Exchanged ",
      ReedmedBalance:"Reedmed Balance",
      WaitingForApprovel:"Waiting For Approvel",
      FirstName:"First Name",
      EnterFirstName:"Enter First Name",
      LastName:"Last Name",
      EnterLastName:"Enter Last Name",
      Username:"Username",
      EnterUsername:"Enter User name",
      Address:"Address",
      EnterAddress:"Enter Address",
      Phone:"Phone",
      EnterPhone:"Enter Phone",
      Update:"Update",
      Login:"Login",
      Register:"Register",
      ForgotPassword:"Forgot Password",
      Enteryouremail:"Enteryouremail",
      SendResetLink:"SendResetLink",
      Enteryouremail:"Enter Your Email",
      SendResetLink:"Send Reset Link",
      Forgotyourpasswordd:"Forgot your password?",
      ClickHere:"Click Here",
      RegisterwithFacebook:"Register with Facebook",
      RegisterwithGoogle:"Register with Google",
      LogInWithFacebook:" Log In With Facebook",
      LogInWithGoogle:"Log In With Google"

    },
  },
  ar: {
    translation: {
      dir: "rtl",
      Home: "الصفحه الرئيسيه",
      About: "الشركه",
      contact: "للتواصل",
      FAQ: "الاسئله",
      profile: "الحساب",
      logout: "تسجيل الخروج",
      Services: "الخدمات",
      exchangeAgency: "وكالة لتبديل العملات",
      Discover: "اكتشف المزيد",
      Check: "تحقق من أسعار الصرف لدينا",
      Enter: "أدخل المبلغ الخاص بك",
      BeeChanger: "Changer Money",
      BeeChangerP: "مرحبًا بكم في منصتنا المتطورة لتحويل الأموال، حيث تلتقي الراحة بالأمان. مع معاملات سلسة بين جميع البنوك الكبرى، نفخر بأن نكون الخيار الأسهل والأكثر أمانًا المتاح. بالإضافة إلى ذلك، نقدم أسعارًا تنافسية لضمان حصولك على أفضل قيمة لأموالك.",
      Exchange: "بدل",
      from: "من",
      to: "إلى",
      provide: " نحن نقدم",
      dservices: "خدمات متنوعة",
      Features: "ميزات",
      Agency: "وكالتنا",
      platform: "تضمن منصتنا السهولة والأمان، وتوفر لك أفضل العروض والأسعار التنافسية.",
      ProfitableExchange: "تبادل مربح",
      High: "مستوى عالٍ من الموثوقية",
      Economic: "تبادل اقتصادي",
      Withdraw: "سحب الإحالات",
      WORKS: "كيف يعمل بى تشينجر",
      Quality: "نحن نهتم بالجودة",
      Qualitatively: "تغيير العملة عبر الإنترنت بجودة عالية",
      somethingWrong: "إذا حدث خطأ ما، سنعيد أموالك دائمًا",
      exchangeguarantee: "ضمان تبادل بنسبة 100%",
      Numerous: "تؤكد العديد من المراجعات الإيجابية على جودة عملنا العالية",
      loyalrates: "نحن دائمًا نقدم أسعارًا موالية، ونحن دائمًا منفتحون على التعاون، يرجى الاتصال بنا",
      providereliabilty: "نحن نوفر ليس فقط الموثوقية وأفضل الأسعار، ولكن أيضًا التبادل السريع",
      populardestinations: "نحن نقوم بتبادل الأموال في الوجهات الأكثر شعبية، يمكنك دائمًا تبادل أموالك",
      exchangedirections: "الكثير من اتجاهات التبادل",
      FastExchange: "تبادل سريع",
      Reviews: "التقييمات",
      and: "و",
      Opinions: "الآراء",
      feedback: "تعزز ملاحظات العملاء التزامنا بأن نكون الأفضل في الصناعة، مما يضمن أن يكون كل تبادل سلسًا وآمنًا ومفيدًا.",
      ContactUs: "اتصل بنا",
      Touch: "ابقى على تواصل",
      YourName: "اسمك",
      YourSurname: "اسم العائلة",
      YourUsername: "اللقب",
      Yourmail: "بريدك الإلكتروني",
      YourMessage: "رسالتك",
      Subject: "الموضوع",
      SendMessageNow: "أرسل الرسالة الآن",
      OurVision: "رؤيتنا",
      GoodService: "خدمة جيدة",
      ForCommunity: "من أجل المجتمع",
      LongDevelopment: "التنمية على المدى الطويل",
      SavePlanet: "إنقاذ كوكبنا",
      HelpPeople: "مساعدة الناس",
      OurMision: "مهمتنا",
      ChangeHabits: "تغيير العادات",
      BestQuality: "أفضل جودة",
      ThinkBigger: "فكر كبيرا، اعمل أكبر",
      Stability: "الاستقرار والكفاءة",
      Safer: "حياة أكثر أمانًا وأفضل",
      Promise: "وعدنا",
      SustainableRelationship: "العلاقة المستدامة",
      RenewCommitment: "تجديد التزامنا",
      ProvideSolution: "توفير أفضل الحلول",
      ProfitableRelationship: "العلاقة الربحية",
      AdaptNeeds: "التكيف مع احتياجات الناس",
      Transaction: "معاملة",
      Support: "الدعم",
      Easy: "سهل",
      Fast: "سريع",
      Policy: "سياسة",
      Security: "الأمان",
      OurContacts: "طرق الاتصال الخاصة بنا",
      AddressInfo: " العنوان",
      Cairo: "القاهرة، مصر",
      PhoneCalls: " جهات الاتصال",
      EMailAddress: " البريد الإلكتروني",
      MostAsked: "أكثر الأسئلة تكراراً",
      Questions: "",
      Here: "هنا",
      StepsSuccess: "أربع خطواتنا نحو النجاح",
      HappyClients: "عملاء سعداء",
      quickanswers: "نقدم إجابات سريعة للأسئلة الشائعة حول خدماتنا",
      SendReview: "إرسل تقييمك",
      AddReview: "أضف تقييمك",
      Transactions: "كل معاملة تتم بدقة وعناية، مما يضمن عملية سلسة وآمنة من البداية إلى النهاية. ثق بنا للتعامل مع تبادلاتك بكفاءة وموثوقية."
      , SecurePayment: "تضمن منصتنا طرق دفع آمنة، مما يوفر راحة البال لجميع معاملاتك. نحن نعطي الأولوية لسلامتك وخصوصيتك، مما يضمن حماية معلوماتك المالية دائمًا."
      , UniversalAccess: "اختبر الوصول العالمي مع منصتنا، مما يتيح لك إجراء المعاملات من أي مكان في العالم. تم تصميم خدماتنا لتكون مريحة وسهلة الوصول بغض النظر عن مكانك."
      , Policy: "سياساتنا شفافة وتركز على العملاء، ومصممة لضمان تجربة عادلة وآمنة لجميع المستخدمين. نحن ملتزمون بالالتزام بأعلى معايير النزاهة والموثوقية."
      , Transaction1: "المعاملة",
      SecurePayment1: "دفع آمن",
      UniversalAccess1: "الوصول العالمي",
      Policy1: "السياسة",
      terms: "الشروط",
      Follow: "تابع",
      Password: "كلمه المرور",
      Donthave: "ليس لديك حساب؟",
      CreateNow: "أنشئ الآن",
      YourPhone: "هاتفك",
      Register: "تسجيل",
      HaveAccount: "هل لديك حساب؟",
      LoginHere: "تسجيل الدخول هنا",
      SignIn: "تسجيل الدخول",
      Send: "ارسال",
      ReceiveAmount: "استلام المبلغ",
      Receive: "استلام",
      Next: "التالي",
      Wallet: "المحفظة",
      ReceiveWallet: "استلام المحفظة",
      Proofpayment: "إثبات الدفع",
      ConfirmPassword: "تاكيد كلمه المرور",
      TransactionsNav: "المعاملات",
      Blogs: "المدونات",
      Bonus: "البونص",
      Dashbard: "لوحة التحكم",
      Withdraw: "سحب",
      NewWithdraw: "سحب جديد",
      AllWithdraw: "كل السحوبات",
      Tickets: "تذاكر",
      NewTicket: "تذكرة جديدة",
      PendingTransactions: "المعاملات المعلقة",
      ProcessingTransactions: "المعاملات الجارية",
      CompletedTransactions: "المعاملات المكتملة",
      CanceledTransactions: "المعاملات الملغاة",
      RefundedTransactions: "المعاملات المستردة",
      AllTransactions: "كل المعاملات",
      InviteFriends: "ادعو صديق ",
      Afflication: "الإحالة",
      Dashboard: "لوحه التحكم",
      Exchanger: "المستبدل",
      SendFrom: "إرسال من",
      SendAmount: "المبلغ المرسل",
      GetTo: "الحصول على",
      GetAmount: "المبلغ المستلم",
      Date: "التاريخ",
      NoPrevioustransactions: "لا توجد معاملات سابقة",
      RECENT: "الأحدث",
      ReadMore: "اقرأ المزيد",
      Search: "بحث",
      Withraw: "اسحب",
      fromYour: "من",
      Balance: "الرصيد",
      CurrentBalance: "الرصيد الحالي",
      Points: "النقاط",
      Level: "المستوى",
      PendingExchange: "تبادل معلق",
      ApprovedExchange: "تبادل موافق عليه",
      ProcessingExchange: "تبادل قيد المعالجة",
      CompletedExchange: "تبادل مكتمل",
      afflicationpoints: "نقاط الدعوة",
      Bonuspoints: "نقاط المكافأة",
      Exchangepoints: "نقاط التبادل",
      CanceledExchange: "تبادل ملغى",
      RefundedExchange: "تبادل مسترد",
      RefusedExchange: "تبادل مرفوض",
      TransactionID: "رقم المعاملة",
      TimeCanbeCanceled: "الوقت الذي يمكن فيه الإلغاء",
      PaymentProof: "إثبات الدفع",
      Amount: "المبلغ",
      Comment: "التعليق",
      Status: "الحالة",
      NoPreviousWithdraw: "لا توجد سحوبات سابقة",
      Reserve: "احجز",
      YourTicket: "تذكرتك",
      invitionlink: "رابط الدعوة",
      Generate: "انشأ",
      CopyLink: "نسخ الرابط",
      CopyLink: "نسخ الرابط",
      Image: "صورة",
      Name: "الاسم",
      NoPreviousTickets: "لا توجد تذاكر سابقة",
      userinvitedid: "معرف المستخدم المدعو",
      userinvitedusername: "اسم المستخدم المدعو",
      codeinvitedwith: "الرمز المستخدم للدعوة",
      GotBonus: "تم الحصول على مكافأة",
      NoPreviousAfflications: "لا توجد دعوات سابقة",
      Message: "رسالتك",
      topNav: "للدعم الفنى او لاكمال التحويل بشكل اسرع يرجى التواصل معنا عبر الواتساب",
      Recieve: "استقبال",
      Other: "اخرى",
      UpdateProfile: "تعديل الملف الشخصى",
      Pages: "الصفحات",
      Company: "الشركه",
      NumberOfExchanges : "عدد التبادلات",
      TotalMoneyExchanged : "إجمالي المال المتبادل",
      ReedmedBalance : "الرصيد المسترد",
      WaitingForApprovel : "في انتظار الموافقة",
      FirstName: "الاسم الأول",
EnterFirstName: "أدخل الاسم الأول",
LastName: "الاسم الأخير",
EnterLastName: "أدخل الاسم الأخير",
Username: "اسم المستخدم",
EnterUsername: "أدخل اسم المستخدم",
Address: "العنوان",
EnterAddress: "أدخل العنوان",
Phone: "رقم الهاتف",
EnterPhone: "أدخل رقم الهاتف",
Update: "تحديث",
Login:"تسجيل الدخول",
Register:"انشاء حساب",
SendResetLink: "إرسال رابط إعادة التعيين",
Enteryouremail: "أدخل بريدك الإلكتروني",
SendResetLink: "إرسال رابط إعادة التعيين",
Forgotyourpasswordd: "هل نسيت كلمة المرور؟",
ClickHere: "اضغط هنا",
ForgotPassword:"نسيت كلمه المرور ",
RegisterwithFacebook: "سجل باستخدام فيسبوك",
RegisterwithGoogle: "سجل باستخدام جوجل",
LogInWithFacebook: "تسجيل الدخول باستخدام فيسبوك",
LogInWithGoogle: "تسجيل الدخول باستخدام جوجل",
Loading:"يرسل"
    





    },
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "en", // Default language
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
