import React, { useEffect, useState } from "react";
import axios from "axios";
import Footer from "./Footer";
import { Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import i18n from "./i18n";
function ProcessingTransactions() {
    const [methods, setMethods] = useState([]);
    const [userId, setUserId] = useState(0);
    const [references, setReferences] = useState([]);
    const [payExchanges, setPayExchanges] = useState([]);
    const { t } = useTranslation();

    const changeLanguage = (language) => {
      i18n.changeLanguage(language, (err, t) => {
        if (err) return console.log("Error loading language:", err);
        // console.log("Language changed to:", language);
      });
    };
    useEffect(() => {
        const fetchData = async () => {
            try {
                await loadMethods();
                await loadReferences();
                await loadPayExchanges();
                const userId = localStorage.getItem("id");
                if (!userId) {
                      window.location.href = '/';
                    throw new Error("User ID not found in localStorage");
                }
                setUserId(userId);
            } catch (error) {
                console.error("Error fetching data:", error);
                // Handle error or redirect to login page
            }
        };
        fetchData();
    }, []);

    const loadMethods = async () => {
        try {
            const result = await axios.get(`https://changermoney.com/exchange/dashboard/exchanges/readAll.php?status=1&&user_id=${userId}`);
            setMethods(result.data);
        } catch (error) {
            console.error("Error loading methods:", error);
        }
    };

    const loadReferences = async () => {
        try {
            const result = await axios.get("https://changermoney.com/exchange/dashboard/pay_methods/readAll.php");
            setReferences(result.data);
        } catch (error) {
            console.error("Error loading references:", error);
        }
    };

    const loadPayExchanges = async () => {
        try {
            const result = await axios.get("https://changermoney.com/exchange/dashboard/pay_exchange/readAll.php");
            setPayExchanges(result.data);
        } catch (error) {
            console.error("Error loading pay exchanges:", error);
        }
    };

    const userMethods = methods.filter(method => method.user_id === userId);
    const isMobile = window.innerWidth <= 900;

   

    return (
        <>
            <section className="infos infodifferr" id="infos">
                <div className="container">
                {isMobile ? (
                        <div className="mobile-view">
                            {userMethods.length === 0 ? (
                                <p style={{ textAlign: "center" }} className="my-5">{t("NoPrevioustransactions")}</p>
                            ) : (
                                userMethods.map((method, index) => (
                                    <div key={index} className="transaction-row">
                                        <div className="transaction-item">
                                            <strong>{t("PaymentProof")}:</strong>
                                            <img src={`https://changermoney.com/exchange/uploads/exchanges/${method.image}`} alt="" height="100px" />
                                        </div>
                                        {references.map(ref => (
                                            ref.id === method.first_method_id && (
                                                <>
                                                    <div className="transaction-item">
                                                        <strong>{t("SendFrom")}:</strong>

                                                        <div key={ref.id}>
                                                            <img src={`https://changermoney.com/exchange/uploads/pay_methods/${ref.image}`} alt="" height="100px" />
                                                            <p><span>{ref.name_en}</span></p>
                                                        </div>

                                                    </div>
                                                    <div className="transaction-item">
                                                        <strong>{t("SendAmount")}:</strong>
                                                        <span>{method.amount_send}  {ref.currency}</span>
                                                    </div>
                                                </>
                                            )
                                        ))}
                                        {references.map(ref => (
                                            ref.id === method.second_method_id && (
                                                <>
                                                    <div className="transaction-item">
                                                        <strong>{t("GetTo")}:</strong>

                                                        <div key={ref.id}>
                                                            <img src={`https://changermoney.com/exchange/uploads/pay_methods/${ref.image}`} alt="" height="100px" />
                                                            <p><span>{ref.name_en}</span></p>
                                                        </div>

                                                    </div>
                                                    <div className="transaction-item">
                                                        <strong>{t("GetAmount")}:</strong>
                                                        <span>{method.amount_recieve}  {ref.currency}</span>
                                                    </div>
                                                </>
                                            )
                                        ))}
                                        <div className="transaction-item">
                                            <strong>{t("TransactionID")}:</strong>
                                            <span>{method.transaction_id}</span>
                                        </div>
                                        <div className="transaction-item">
                                            <strong>{t("Status")}:</strong>
                                            <span>{method.status == 0 ? 'pending' : method.status == 1 ? 'processing' : method.status == 2 ? 'completed' : method.status == 3 ? 'refunded' : null}</span>
                                        </div>

                                        <div className="transaction-item">
                                            <strong>{t("Date")}:</strong>
                                            <span>{method.date}</span>
                                        </div>
                                    </div>
                                ))
                            )}
                        </div>
                    ) : (
                        <Table className="desktop-view">
                            <thead>
                                <tr className="headerHome">
                                    <th>{t("PaymentProof")}</th>
                                    <th>{t("SendFrom")}</th>
                                    <th>{t("SendAmount")}</th>
                                    <th>{t("GetTo")}</th>
                                    <th>{t("GetAmount")}</th>
                                    <th>{t("TransactionID")}</th>
                                    <th>{t("Date")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {userMethods.length === 0 ? (
                                    <tr>
                                        <td colSpan="7" style={{ textAlign: "center" }}>{t("NoPrevioustransactions")}</td>
                                    </tr>
                                ) : (
                                    userMethods.map((method) => (
                                        <tr key={method.transaction_id}>
                                            <td>
                                                <img src={`https://changermoney.com/exchange/uploads/exchanges/${method.image}`} alt="" height="100px" />
                                            </td>
                                            {references.map(ref => (
                                                ref.id === method.first_method_id ? (
                                                    <>
                                                        <td>
                                                            <img src={`https://changermoney.com/exchange/uploads/pay_methods/${ref.image}`} alt="" height="100px" />
                                                            <p>{ref.name_en}</p>
                                                        </td>
                                                        <td>{method.amount_send} {ref.currency}</td>
                                                    </>
                                                ) : null
                                            ))}
                                            {references.map(ref => (
                                                ref.id === method.second_method_id ? (
                                                    <>
                                                        <td>
                                                            <img src={`https://changermoney.com/exchange/uploads/pay_methods/${ref.image}`} alt="" height="100px" />
                                                            <p>{ref.name_en}</p>
                                                        </td>
                                                        <td>{method.amount_recieve} {ref.currency}</td>
                                                    </>
                                                ) : null
                                            ))}
                                            <td>{method.transaction_id}</td>

                                            <td>{method.date}</td>
                                        </tr>
                                    ))
                                )}
                            </tbody>
                        </Table>
                    )}
                </div>
            </section>
            <Footer />
        </>
    );
}

export default ProcessingTransactions;
