import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Footer from "./Footer";
import { Link } from "react-router-dom";
import { EffectFade, Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { AiOutlineSearch } from "react-icons/ai";
import axios from "axios";
import { useTranslation } from "react-i18next";
import i18n from "./i18n";
import parse from 'react-html-parser';

function Bonus() {
  const [search, setSearch] = useState("");
  const [bonus, setBlog] = useState([]);
  const { t } = useTranslation();

  const changeLanguage = (language) => {
    i18n.changeLanguage(language, (err, t) => {
      if (err) return console.log("Error loading language:", err);
      // console.log("Language changed to:", language);
    });
  };
  useEffect(() => {
    loadBlog();
  }, []);

  const loadBlog = async () => {
    const result = await axios.get(
      "https://changermoney.com/exchange/dashboard/bonus/readAll.php"
    );
    setBlog(result.data.reverse());
  };
  return (
    <div className="bloggg">

      <Container className="SHOPALLL">
        <Row>
          <Col lg="9" md="9" sm="9">
            <div>
              {bonus
                .filter((blog) => {
                  if (search === "") {
                    return blog;
                  } else if (
                    blog.title.toLowerCase().includes(search.toLowerCase())
                  ) {
                    return blog;
                  }
                })
                .map((blog) => (
                  <div key={blog.id}>
                    <Link to={`/Bonus/${blog.id}`} className="lllink">
                      <div
                        className="blogitemmm wow slideInLeft row align-items-center"
                        data-wow-duration="2s"
                        data-wow-delay="0.1s"
                      >
                        <div className="col-md-6">
                          <img
                            src={`https://changermoney.com/exchange/uploads/bonuss/${blog.image}`}
                            alt=""
                            
                          />
                        </div>
                        <div className="col-md-6">
                          <h3>{t("dir") == "ltr" ? blog.title : blog.title_ar}</h3>

                          <p>{t("dir") == "ltr" ? parse(blog.description) : parse(blog.description_ar)}</p>

                          <button>{t("ReadMore")}</button>
                        </div>
                      </div>
                    </Link>
                  </div>
                ))}
            </div>
          </Col>
          <Col
            lg="3"
            md="3"
            sm="3"
            className="blogfilter wow slideInRight"
            data-wow-duration="2s"
            data-wow-delay="0.2s"
          >
            <div className="d-flex p-4">
              <input
                type="text"
                className="searchhhh"
                placeholder={t("Search")}
                onChange={(event) => {
                  setSearch(event.target.value);
                }}
              />
              <AiOutlineSearch className="searchhhhicon" />
            </div>
           
            <div className="thirddivblog">
              <h3 className="thirddivh3">{t("RECENT")}</h3>
              {bonus.slice(0,3).map((blog) => (
                <Link to={`/Bonus/${blog.id}`}>
              <div
                
                className="lllink"
              >
                <div className="divforthird">
                  <div>
                    <img
                    src={`https://changermoney.com/exchange/uploads/bonuss/${blog.image}`}
                    alt=""
                    />
                  </div>
                  <div>
                    <h3>{t("dir") == "ltr" ? blog.title : blog.title_ar}</h3>

                  </div>
                </div>
              </div>
              </Link>
              ))}
            </div>
           
          </Col>
        </Row>
      </Container>
      <footer>
        <Footer />
      </footer>
    </div>
  );
}

export default Bonus;