import React, { Component, useEffect, useState } from "react";
import Footer from "./Footer";
import { Col, Container, Row } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { AiOutlineSearch } from "react-icons/ai";
import axios from "axios";
import { useTranslation } from "react-i18next";
import i18n from "./i18n";
import parse from 'react-html-parser';

function Blogdetail() {
  const { id } = useParams();
  const [blogs, setBlog] = useState([]);
  const { t } = useTranslation();

  const changeLanguage = (language) => {
    i18n.changeLanguage(language, (err, t) => {
      if (err) return console.log("Error loading language:", err);
      // console.log("Language changed to:", language);
    });
  };
  useEffect(() => {
    loadBlog();
  }, []);

  const loadBlog = async () => {
    const result = await axios.get(
      "https://changermoney.com/exchange/dashboard/blog/readAll.php"
    );
    setBlog(result.data.reverse());
  };
  return (
    <div>
      <Container className="SHOPALLL">
        <Row>
          <Col lg="3" md="3" sm="3" className="blogfilter ">

            <div className="thirddivblog">
              <h3 className="thirddivh3">{t("RECENT")}</h3>
              {blogs.slice(0, 3).map((blog) => (
                <Link to={`/Blogs/${blog.id}`}>
                  <div className="lllink">
                    <div className="divforthird">
                      <div>
                        <img
                          src={`https://changermoney.com/exchange/uploads/blogs/${blog.image}`}
                          alt=""
                        />
                      </div>
                      <div>
                        <h3>
                          {t("dir") == "ltr" ? blog.title : blog.title_ar}
                        </h3>
                      </div>
                    </div>
                  </div>
                </Link>
              ))}
            </div>

          </Col>
          {blogs.map((blog) => (
            <>
              {blog.id == id ? (
                <Col lg="9" md="9" sm="9" className="bllogsdetail">
                  <img
                    src={`https://changermoney.com/exchange/uploads/blogs/${blog.image}`}
                    alt=""
                  />
                  <h3>{t("dir") == "ltr" ? blog.title : blog.title_ar}</h3>
                  <p>{t("dir") == "ltr" ? parse(blog.description) : parse(blog.description_ar)}</p>

                
                </Col>
              ) : (
                ""
              )}
            </>
          ))}
        </Row>
      </Container>
      <Footer />
    </div>
  );
}

export default Blogdetail;