import React, { useEffect, useState } from "react";
import axios from "axios";
import Footer from "./Footer";
import { Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import i18n from "./i18n";
function AllWithdraw() {
    const [methods, setMethods] = useState([]);
    const [userId, setUserId] = useState(0);
    const [references, setReferences] = useState([]);
    const { t } = useTranslation();
    const changeLanguage = (language) => {
        i18n.changeLanguage(language, (err, t) => {
            if (err) return console.log("Error loading language:", err);
            // console.log("Language changed to:", language);
        });
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                await loadMethods();
                await loadReferences();
                const userId = localStorage.getItem("id");
                if (!userId) {
                    window.location.href = '/';
                    throw new Error("User ID not found in localStorage");
                }
                setUserId(userId);
            } catch (error) {
                console.error("Error fetching data:", error);
                // Handle error or redirect to login page
            }
        };
        fetchData();
    }, []);

    const loadMethods = async () => {
        try {
            const result = await axios.get("https://changermoney.com/exchange/dashboard/withdraw/readAll.php?status=1");
            setMethods(result.data);
        } catch (error) {
            console.error("Error loading methods:", error);
        }
    };

    const loadReferences = async () => {
        try {
            const result = await axios.get("https://changermoney.com/exchange/dashboard/pay_methods/readAll.php");
            setReferences(result.data);
        } catch (error) {
            console.error("Error loading references:", error);
        }
    };


    const userMethods = methods.filter(method => method.user_id === userId);





    return (
        <>
            <section className="infos infodifferr" id="infos">
                <div className="container">
                    <Table>
                        <thead>
                            <tr className=" headerHome">
                                <th>
                                    <div className="change">
                                        <div className="change-thumb">

                                            <p><span>{t("Amount")}</span></p>
                                        </div>
                                    </div>
                                </th>
                                <th>
                                    <div className="change">
                                        <div className="change-thumb">

                                            <p><span>{t("GetTo")}</span></p>
                                        </div>
                                    </div>
                                </th>
                                <th>
                                    <div className="amrcat-cap-content">
                                        <p><span>{t("Wallet")}</span></p>
                                    </div>
                                </th>

                                <th>
                                    <div className="change">
                                        <div className="change-thumb">

                                            <p><span>{t("Comment")}</span></p>
                                        </div>
                                    </div>
                                </th>
                                <th>
                                    <div className="amrcat-cap-content">
                                        <p><span>{t("Status")}</span></p>
                                    </div>
                                </th>

                                <th>
                                    <div className="amrcat-cap-content">
                                        <p><span> {t("Date")}</span></p>
                                    </div>
                                </th>

                            </tr>
                        </thead>
                        <tbody>
                            {userMethods.length === 0 ? (
                                <p style={{ textAlign: "center" }} className="my-5">{t("NoPreviousWithdraw")}</p>
                            ) : (
                                userMethods.map((method) => (
                                    <tr>
                                        <>
                                            {
                                                references.map(refrence => (
                                                    refrence.id == method.method_id ?
                                                        <>
                                                            <td>
                                                                <div className="amrcat-cap-content">
                                                                    <p><span>{method.balance} {refrence.currency}</span></p>
                                                                </div>
                                                            </td>



                                                            <td>
                                                                <div className="change">
                                                                    <div className="change-thumb">
                                                                        <img src={`https://changermoney.com/exchange/uploads/pay_methods/${refrence.image}`} alt="" height="100px" />

                                                                        <p><span>{refrence.name_en}</span></p>
                                                                    </div>
                                                                </div>
                                                            </td>

                                                        </>
                                                        : null))}

                                            <td>
                                                <div className="amrcat-cap-content">
                                                    <p><span>{method.wallet}</span></p>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="amrcat-cap-content">
                                                    <p><span>{method.comment}</span></p>
                                                </div>
                                            </td>

                                            <td>
                                                <div className="amrcat-cap-content">
                                                    <p><span>{method.status == 0 ? 'pending' : 'approved'}</span></p>
                                                </div>
                                            </td>

                                            <td>
                                                <div className="amrcat-cap-content">
                                                    <p><span>{method.date}</span></p>
                                                </div>
                                            </td>


                                        </>

                                    </tr>
                                )))}
                        </tbody>

                    </Table>
                </div>
            </section>
            <Footer />
        </>
    );
}

export default AllWithdraw;
